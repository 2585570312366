import { useTranslations } from 'next-intl'
import { FormInput } from 'components/form/FormInput'
import { Button } from 'components/ui/Button'
import { Text } from 'components/ui/Text'
import { useAuthContext } from 'context/auth'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import { toast } from 'components/ui/Toast'
import * as yup from 'yup'
import { ROUTES } from 'constants/routes'
import Link from 'next/link'
import { Logo } from 'components/ui/Logo'
import { AuthLayoutV2 } from 'components/auth/AuthLayoutV2'
import { FeatureFlag } from 'flags'

const schema = (t: ReturnType<typeof useTranslations>) =>
  yup.object({
    username: yup.string().required(t('required_account_name')),
    password: yup.string().required(t('required_password')),
  })

interface SubmitLoginForm {
  username: string
  password: string
}

function LoginPage() {
  const router = useRouter()
  const t = useTranslations()
  const { login } = useAuthContext()

  const formInstance = useForm<SubmitLoginForm>({
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(schema(t)),
  })
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = formInstance

  const onSubmit = async (data: SubmitLoginForm) => {
    try {
      await login(data.username, data.password)
    } catch (error: any) {
      toast.error({ message: error?.message })
    }
  }

  return (
    <AuthLayoutV2
      footerRender={
        <div className="text-center bg-gray-200 pt-4 px-8 pb-8">
          <Text fontSize="sm" v2TextColor="Gray-Normal" className="mb-4">
            {t('become_droppii_partner')}
          </Text>
          <Button
            className="!text-sm max-w-lg capitalize"
            variant="outline"
            fullWidth
            onClick={() => router.push(ROUTES.REGISTRY)}
            disabled={isSubmitting}
          >
            {t('register_now')}
          </Button>
        </div>
      }
    >
      <div
        className="w-full h-[204px] bg-cover bg-left-bottom bg-no-repeat pointer-events-none absolute left-0"
        style={{ backgroundImage: `url(/img/bg-login.png)` }}
      />

      <header className="pt-6 pb-2 flex justify-center max-w-md mx-auto relative">
        <Logo width="174" height="60" isWhite />
      </header>

      <div className="w-full space-y-2 flex justify-center absolute left-0">
        <Text
          v2TextColor="White"
          className="max-w-xs text-center whitespace-pre-wrap"
        >
          {t.rich('droppii_business_slogan', { divider: '\n' })}
        </Text>
      </div>

      <div className="mt-36 relative">
        <Text
          v2TextColor="Gray-Dark"
          fontSize="3xl"
          className="font-bold mb-4 text-center"
        >
          {t('please_login_to_account')}
        </Text>
        <FormProvider {...formInstance}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white max-w-lg m-auto"
          >
            <div className="space-y-4 mb-4">
              <FormInput
                placeholder={t('account_name')}
                name="username"
                autoComplete="username"
                fullWidth
                onClear={() => setValue('username', '')}
                disabled={isSubmitting}
              />
              <FormInput
                placeholder={t('password')}
                name="password"
                type="password"
                autoComplete="current-password"
                fullWidth
                onClear={() => setValue('password', '')}
                disabled={isSubmitting}
              />
              <div className="flex justify-end">
                <Link href={ROUTES.FORGOT_PASSWORD}>
                  <a className="w-fit text-v2-gray-900">
                    {t('forgot_password')}
                  </a>
                </Link>
              </div>

              <Button
                type="submit"
                fullWidth
                asLabel={isSubmitting}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('login')}
              </Button>
            </div>
            <FeatureFlag flagKey="auth.phone_login">
              <div className="flex gap-2 flex-col items-center justify-center pb-10">
                <Text
                  className="lowercase"
                  fontSize="sm"
                  v2TextColor="Gray-Normal"
                >
                  {t('or')}
                </Text>

                <Link href={ROUTES.PHONE_LOGIN}>
                  <a className="text-sm font-medium !text-primary w-fit">
                    {t('login_by_phone')}
                  </a>
                </Link>
              </div>
            </FeatureFlag>
          </form>
        </FormProvider>
      </div>
    </AuthLayoutV2>
  )
}

export default LoginPage
